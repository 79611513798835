* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
:root {
    --mainColor: #1A1A1D;
    --mainColorLight: #4E4E50;
    --secondaryColor: #6F2232;
    --textColor: #E5E5E5;
    --optionColor: #EC1D24;
    --activeColor: #7F00FF;
    --newsbackground: #1C1931;
    --color1: #FB03F5;
    --color2: #AA9CFF;
    --backgroundcolor:#080808;
    --backgroundrgba: rgba(26, 26, 29, 1);
    --heading-h1-font-size: 2.75rem;
    --heading-h2-font-size: 2rem;
    --heading-h3-font-size: 1.5rem;
    --heading-h4-font-size: 1.125rem;
    --heading-h5-font-size: 1rem;
}
.dashboard-container {
    margin-left:19%; 
    padding: 20px;
    /* background-color: hsl(0, 0%, 96%); */

    height: 100%;
    
}
.dashboard-content {
    /* background-color: white; */
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}
.predictionheading{
    color: var(--textColor);
    font-size: var(--heading-h3-font-size);
    font-weight: 600;
    margin-bottom: 20px;
}