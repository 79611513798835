*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    --mainColor: #1A1A1D; 
    --mainColorLight: #4E4E50; 
    --secondaryColor: #6F2232; 
    --textColor: #E5E5E5;
    --optionColor: #EC1D24;
    --activeColor: #7F00FF; 
    --backgroundrgba: rgba(26, 26, 29, 1);
    --secondarytextcolor: #333333;
    --heading-h1-font-size: 2.75rem;
    --heading-h2-font-size: 2rem;
    --heading-h3-font-size: 1.5rem;
    --heading-h4-font-size: 1.125rem;
    --heading-h5-font-size: 1rem;
    --color1: #FB03F5;
    --color2: #AA9CFF;
    --backgroundcolor:#080808;
}
body{
    margin: 0;
    height: 100vh;
    background: radial-gradient(circle at 20% 20%, var(--color1)  -20%, transparent 40%),
    radial-gradient(circle at 105% 40%, var(--color1) -25%, transparent 20%);
    background-color: var(--backgroundcolor); 
    background-repeat: no-repeat;
}


.container {
    margin-left:19%;
    /* background-color: hsl(0, 0%, 96%); */
    width: 80%;
}

.content {
    padding: 20px;
    border-radius: 8px;
}

.AnalysisDetailtitle{
    font-size: var(--heading-h2-font-size);
    color: var(--text-color);

}
.backbtn button{
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border: none;
}
.backbtn button:hover{
    background-color:#131122;
}


.analysisdata img{
    width: 20%;
    height: 10%;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.analysistitle{
    font-size: var(--heading-h2-font-size);
    color: var(--text-color);
    margin-top: 10px;
    font-weight: bold;
}
.analysisdescription{
    font-size: var(--heading-h3-font-size);
    color: var(--text-color);
    margin: 2% 10%;
}
.analysisRank{
    font-size: var(--heading-h3-font-size);
    color: var(--text-color);
    margin: 2% 10%;
}
.analyisbtn{
    display: flex;
    justify-content: center;
    align-items: center;
}
.analyisbtn button{
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border: none;
}



@media(max-width:900px){
    .container{
        margin-left: 0%;
        /* background-color: red; */
        width: 100%;
    }
    
}