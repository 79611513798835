:root {
    --mainColor: #1A1A1D;
    --mainColorLight: #4E4E50;
    --secondaryColor: #6F2232;
    --textColor: #E5E5E5;
    --optionColor: #EC1D24;
    --activeColor: #7F00FF;
    --newsbackground: #1C1931;
    --color1: #FB03F5;
    --color2: #AA9CFF;
    --backgroundcolor:#080808;
    --backgroundrgba: rgba(26, 26, 29, 1);
    --heading-h1-font-size: 2.75rem;
    --heading-h2-font-size: 2rem;
    --heading-h3-font-size: 1.5rem;
    --heading-h4-font-size: 1.125rem;
    --heading-h5-font-size: 1rem;
}

.dashboard-container {
    margin-left:19%; 
    padding: 20px;
    height: 100%;
}

.dashboard-content {
    /* background-color: white; */
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}


@media(max-width:900px){
    .dashboard-container{
        margin-left: 0%;
        /* background-color: red; */
        width: 100%;
    }
    
}
@media (max-width: 400px) {
    .dashboard-container{
        padding: 0px;
    }
}


/* trending */
.Pagetitle{
    font-size: var(--heading-h3-font-size);
    color: var(--textColor);
    width: 100%;
    font-weight: bold;
    

}


/* portfolio */
.mainprotfoliocard{
    /* background-color: var(--color1); */
    /* width: 70%; */
    height: 50vh;
    padding: 5%;
    /* margin: 5%; */
    border-radius: 20px;
    overflow-y: auto;

}
.mainprotfoliocard::-webkit-scrollbar {
    display: none;
}