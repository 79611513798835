*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    --text-color: #E6E6FA;
    --Button-color: #FF3333;
    --background-color: #1C1931;
    --rgba: 28, 25, 49;
    --hover: #FFA500;
    --secondarytextcolor: #333333;
}
/* body{
    overflow-y: hidden;
} */

.loginpage{
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginform{
    background-color:rgba(var(--rgba), 0.3);
    width: 40vw;
    height: 90%;
    padding: 2%;
    margin: 7% auto;
    border-radius: 20px;
}
.Signupform{
    background-color:rgba(var(--rgba), 0.3);
    width: 40vw;
    height: 90%;
    padding: 2%;
    margin: 7% auto;
    border-radius: 20px;
}

.auth-link {
    font-size: 1em;
    margin: 0 2%;
    color: var(--text-color);
}

.auth-link:hover {
    color: var(--hover);
}
.auth-link.active {
    border-bottom: 1px solid var(--text-color);
    padding-bottom: 2px;
}

.separator {
    font-size: 1em;
    color: var(--text-color);
}

.auth-link.active {
    border-bottom: 1px solid var(--text-color);
    padding-bottom: 2px;
}

form {
    margin: 2% 15%;
}

.labels {
    color: var(--text-color);
}

.userinputfield {
    width: 20vw;
    height: 5vh;
    border-radius: 10px;
    padding: 0 10px;
    color: var(--secondarytextcolor);
    font-size: 1em; /* Adjust font size for better readability */
}

.userinputfield:active,
.userinputfield:focus {
    outline: none;
    border: none;
}

.submit-btn button {
    width: 10vw;
    border-radius: 10px;
    height: 5vh;
    background-color: var(--Button-color);
    color: var(--text-color);
}

.or-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20vw;
}
.or-line {
    width: 20px;
    flex: 1;
    height: 1px;
    background-color: var(--text-color);
}

.or-text {
    margin: 0 10px;
    color: var(--text-color);
}

.social-btn img {
    display: flex;
    justify-content: center;
    align-items: center;
    width:2vw;
}
.eyeicon{
    color: var(--background-color);
}

/* responsive design for less than 900px */
@media (max-width: 900px) {
    .loginform {
        width: 60vw;
        /* height: 60vh; */
        margin: 15% auto;
    }
    .Signupform{
        width: 60vw;
        /* height: 60vh; */
        margin: 10% auto;
    }
    form {
        margin: 2% 5%;
    }
    .userinputfield {
        width: 60vw;
        font-size: 1.2em;
    }
    
    .or-container {
        width:45vw;
    }
    .submit-btn button {

        width: 40vw;
    }
    .social-btn img {
        width: 5vw;
        height: 5vh;
        
    }
    
}

/* Responsive design for screens smaller than 600px */
@media (max-width: 600px) {
    .loginform {
        width: 80vw;
        /* height: 90vh; */
        padding: 5%;
        margin-top: 30%;
    }
    .Signupform{
        width: 80vw;
        /* height: 90vh; */
        padding: 5%;
        margin-top: 15%;
    }
    form {
        margin: 2% 5%;
    }
    @media (max-width: 600px) {
        .userinputfield {
            width: 70vw;
            font-size: 1.4em; 
        }
    }
    
}

@media (max-height:800px) {
    .loginform {
        /* height: 90vh; */
        width: 60vw;
        margin: 3% 0;
    }
    .Signupform{
        
        /* height: 90vh; */
        width: 60vw;
        margin: 1% auto;
    }
    .social-btn img{
        width: 4vw;
        margin: 0 5px;
    }
    
}

@media (max-width: 600px) {
    .loginform {
        width: 80vw;
        /* height: 90%; */
        padding: 5%;
        margin-top: 20%;
    }
    .Signupform{
        
        width: 80vw;
        /* height: 90%; */
        padding: 5%;
        margin-top: 20%;

    }
    form {
        margin: 2% 5%;
    }
    .userinputfield {
        width: 70vw;
        font-size: 1.4em; 
    }
    .social-btn img{
        width: 7vw;
        margin: 0 5px;
    }
}

@media (max-width:400px) {
    
    .loginform {
        margin-top: 25%;
        /* height: 75vh; */
        width: 90vw;
    
    }
    .Signupform{
        margin: 10% 5%;
        width: 90vw;
        overflow-y: hidden;
        
    }
    .social-btn img{
        width: 10vw;
        height: 5vh;
        margin: 0 5px;
    }
    .or-container {
        width: 70vw;
    }

    
    
}

@media (min-height: 1200px) and (max-height: 1900px) {
    .loginform {

        width: 90vw; 
        height: 100%; 
        padding: 3%;
        margin: 20% auto; 
        border-radius: 20px;
    }
    .Signupform{
        width: 90vw; 
        height: 100%; 
        padding: 3%;
        margin: 20% auto; 
        border-radius: 20px;
    }
    .auth-link {
        font-size: 2.5em; 
    }
    .separator {
        font-size: 2.2em; 
    }
    form {
        margin: 4% 10%;
    }
    .labels {
        font-size: 2.2em; /* Increased font size */
    }
    .userinputfield {
        width: 25vw; 
        height: 6vh; 
        font-size: 2.2em;
    }
    .submit-btn button {
        margin-top: 5%;
        width: 40vw; /* Increased width */
        height: 6vh; /* Increased height */
        font-size: 2.2em; /* Increased font size */
    }
    .or-container {
        width: 50vw; /* Increased width */
    }
    .or-line {
        width: 30px; /* Increased width */
    }
    .or-text {
        font-size: 1.2em; /* Increased font size */
    }
    .social-btn img {
        width: 7vw; 
        height: 5vh;
    }
    input[type="text"],
    input[type="password"] { 
        height: 6vh; 
        font-size: 2em; 
        padding: 1rem; 
        margin-top: 1rem; 
        border-radius: 10px; 
        border: 1px solid #ccc;
    }
    .forgotpass{
        font-size: 1.5em;
        margin-top: 2%;
    }
    .eyeicon {
        font-size: 1.5em; 
        margin-top: 40px;
    }
}


