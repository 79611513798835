@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Kadwa:wght@400;700&family=Lato:wght@300&family=Roboto:wght@100;300&family=Tsukimi+Rounded:wght@500&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Inter', sans-serif;
}

:root {
    --mainColor: #1A1A1D; 
    --mainColorLight: #4E4E50; 
    --secondaryColor: #6F2232; 
    --textColor: #E5E5E5;
    --optionColor: #EC1D24;
    --activeColor: #7F00FF; 
    --backgroundrgba: rgba(26, 26, 29, 1);
}

.Navbar {
    background-color: transparent;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    position: relative;
    
}

.menubars {
    color: var(--textColor);
    font-size: 24px;
    cursor: pointer;
    display: none;
    
    
}

.search-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    margin-left: 2%;
}

.search-input {
    padding: 8px;
    border: none;
    border-radius: 4px 0 0 4px;
    outline: none;
    width: 100%;
    max-width: 300px;
}

.search-button {
    padding: 8px;
    background-color: var(--mainColorLight);
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    color: var(--textColor);
}

.user-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--textColor);
    cursor: pointer;
}

.nav-menu {
    background-color: var(--backgroundrgba);
    width: 18vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 0.85s;
    padding-top: 20px;
    z-index: 10;
    
}

.nav-menu.active {
    left: 0;
    transition: 0.35s;
}

.sidebar-header {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 20px;
}

.logo-img {
    width: 20%;
    height: auto; /* Adjusted to keep aspect ratio */
}

.logo-name {
    font-size: 2em;
    color: var(--textColor);
    margin-left: 2px;
    font-weight: bold;
}

.nav-menu-items {
    width: 100%;
    padding: 0;
    margin: 0;
    flex-grow: 1;
}

.nav-text {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    position: relative;
    margin-bottom: 20px;
    font-size: 18px;
    transition: background-color 0.3s ease;
}

.nav-text a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: var(--textColor);
    display: flex;
    align-items: center;
    border-radius: 8px;
    transition: color 0.3s ease;
}

.nav-text a:hover {
    color: var(--activeColor);
}

.active-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: var(--activeColor);
    border-radius: 2px;
}

.nav-text .fa-icon {
    margin-right: 16px;
}

span {
    margin-left: 16px;
}

.logout-container {
    display: flex;
    align-items: center;
    padding: 20px;
    border-top: 1px solid var(--mainColorLight);
}

.logout-container button {
    text-decoration: none;
    color: var(--textColor);
    display: flex;
    align-items: center;
}

.logout-container a span {
    margin-left: 10px;
}

.Marklogo {
    width: 100%;
    display: flex;
    align-items: center;
}

.user-container-vertical {
    display: none;
    justify-content: center;
    align-items: center;
    color: var(--textColor);
    cursor: pointer;
    margin-bottom: 20px;
}
.user-img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

/* Responsive Adjustments */
@media screen and (max-width: 900px) {
    .menubars {
        display: block;
    }

    .nav-menu {
        width: 40vw;
    }

    .search-container {
        flex-grow: 2;
    }

    .logo-img {
        width: 20vw;
    }

    .logo-name {
        font-size: 1.8em;
    }

    .user-container-vertical {
        display: flex;
        justify-content: start;
        margin-left: 5%;
    }

    .logo-img {
        width: 10vw;
    }

    .logo-name {
        font-size: 1.8em;
    }
}

@media screen and (max-width: 600px) {
    .logo-img {
        width: 15vw;
    }

    .logo-name {
        font-size: 1.5em;
    }

    .nav-text {
        font-size: 16px;
    }

    .nav-menu {
        width: 60vw;
    }

    .search-input {
        max-width: 100%;
    }
}


/* @media screen and (max-width: 841px) {
    .nav-menu {
         width: 40vw;
    }
} */
