/* Global styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Color Variables */
:root {
    --mainColor: #1A1A1D;
    --mainColorLight: #4E4E50;
    --secondaryColor: #6F2232;
    --textColor: #E5E5E5;
    --activeColor: #7F00FF;
    --backgroundColor: #080808;
    --heading-h1-font-size: 2.5rem;
    --heading-h2-font-size: 2rem;
    --heading-h3-font-size: 1.5rem;
    --link-spacing: 15px;
    --buttombgcolor:#9C51A0;
    --iconbgcolor:#8c8b92;

}

/* Dashboard container */
.dashboard-container {
    margin-left: 19%;
    padding: 20px;
    height: 100%;
    /* background-color: var(--backgroundColor); */
}

/* Dashboard content */
.dashboard-content {
    padding: 20px;
    border-radius: 8px;
}




/* Settings heading */
.settingsheading h1 {
    font-size: var(--heading-h2-font-size);
    font-weight: normal;
    color: var(--textColor);
    margin-bottom: 20px;
}

/* Settings content */
.settingscontent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: var(--link-spacing);
    margin-right: 5%;
    border-bottom: 3px solid var(--mainColorLight);
}

.settingtitle {
    font-size: var(--heading-h3-font-size);
    color: var(--textColor);
    text-decoration: none;
    transition: color 0.3s ease;
}

.settingtitle:hover {
    color: var(--mainColorLight);
}

.usericon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 15%;
    border-radius: 50%;
    margin-right: 10px;
    padding: 10px;
    background-color: var(--iconbgcolor);
    opacity: 0.8;
}
.fonticons{
    width: 3vw;
    height: 10vh;
    color: var(--textColor);
}
input[type="file"] {
    display: none;
}

label[for="image"] {
    margin: 5% 0;
    background-color: var(--buttombgcolor);
    color:var(--backgroundColor);
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: var(--heading-h3-font-size);
    transition: background-color 0.3s ease;
}

label[for="image"]:hover {
    background-color: var(--mainColorLight);
}
.imagesubmitbtn{
    background-color: var(--buttombgcolor);
    color:var(--backgroundColor);
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: var(--heading-h4-font-size);
    transition: background-color 0.3s ease;

}
.imagesubmitbtn:hover {
    background-color: var(--mainColorLight);
}
.userpersonalinfo{
    font-size: var(--heading-h3-font-size);
    font-weight: normal;
    color: var(--textColor);
}

.numberbutton{
    background-color: var(--buttombgcolor);
    color:var(--backgroundColor);
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: var(--heading-h4-font-size);
    transition: background-color 0.3s ease;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: var(--backgroundColor);
    color: var(--textColor);
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
}

.modal-content input {
    width: 90%;
    padding: 10px;
    margin: 10px 0;
    color: var(--backgroundColor);
    border: 1px solid var(--mainColorLight);
    border-radius: 5px;
}

.modal-content button {
    margin: 5px;
    padding: 10px 20px;
    background-color: var(--buttombgcolor);
    color: var(--textColor);
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-content button:hover {
    background-color: var(--secondaryColor);
}
/* reset  password*/
.resetheading h2{
    font-size: var(--heading-h2-font-size);
    font-weight: normal;
    color: var(--textColor);
    margin-bottom: 20px;
}
.password-input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.password-input-container input {
    padding-right: 2.5rem; /* Add space for the icon */
}

.password-toggle-icon {
    position: absolute;
    right: 1rem; 
    cursor: pointer;
    color: #6c757d; 
}

.change-password-btn {
    background-color:var(--buttombgcolor);
    color: white;
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.change-password-btn:hover {
    background-color:var(--mainColorLight);
}
.termstitle{
    font-size: var(--heading-h3-font-size);
    color: var(--textColor);
    font-weight: bold;
    text-decoration: none;

}
.termsdescription{
    font-size: var(--heading-h4-font-size);
    color: var(--textColor);
    text-decoration: none;

}
.aboutmain {
    margin: 5%;
}
  
.aboutcontent {
font-size: var(--heading-h3-font-size);
color: var(--textColor);
text-decoration: none;
width: 80%;
}

.logoimg {
display: flex;
justify-content: center;
align-items: center;
}

.aboutimg {
    width: 20vw;
}

@media (max-width: 768px) {
.aboutimg {
    width: 40vw;
}

.aboutcontent {
    font-size: calc(var(--heading-h3-font-size) - 2px);
    width: 100%;
}
}


/* Media Queries for Responsive Design */
@media (max-width: 900px) {
    .dashboard-container {
        margin-left: 0;
        width: 100%;
    }

    .settingscontent {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}

@media (max-width: 500px) {
    .usericon{
        width: 25%;
        height: 20%;
    }
    .fonticons{
        width: 10vw;
    }
    .dashboard-container {
        padding: 10px;
    }

    .settingsheading h1 {
        font-size: 1.8rem;
    }

    .settingtitle {
        font-size: 1.2rem;
        text-align: center;
    }
}
