:root {
    --mainColor: #1A1A1D;
    --mainColorLight: #4E4E50;
    --secondaryColor: #6F2232;
    --textColor: #E5E5E5;
    --optionColor: #EC1D24;
    --activeColor: #7F00FF;
    --newsbackground: #1C1931;
    --color1: #FB03F5;
    --color2: #AA9CFF;
    --backgroundcolor:#080808;
    --portfolio-background: #0F0757;
    --backgroundrgba: rgba(26, 26, 29, 1);
    --heading-h1-font-size: 2.75rem;
    --heading-h2-font-size: 2rem;
    --heading-h3-font-size: 1.5rem;
    --heading-h4-font-size: 1.125rem;
    --heading-h5-font-size: 1rem;
}

.portfoliocard{
    background-color: var(--portfolio-background);
    width: 100%;
    height: 60%;
    padding: 5%;
    margin-top: 2%;
}
.cryptocontent{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.cryptotitle{
    color: var(--textColor);
    font-size: var(--heading-h3-font-size);
    font-weight: bold;
}

.cardname{
    display: flex;
}
.cardname img{
    width: 20%;
    height: 20%px;
    border-radius: 50%;
}

.deletebtn button{
    /* background-color: var(--activeColor); */
    color: var(--textColor);
    border: none;
    border-radius: 10px;
    padding: 5px;
    /* margin: 5px; */
}

.price{
    padding-bottom: 5%;
}
.Pcardtitle{
    color: var(--textColor);
    font-size: var(--heading-h4-font-size);
    font-weight: bold;
    /* margin-top: 5%; */
}
.price p{
    color: var(--textColor);
    font-size: var(--heading-h5-font-size);
    font-weight: lighter;
    
}

@media (max-width: 500px) {
    .portfoliocard {
        padding-bottom:10%;
        border-radius: 15px;
        height: 95%;
    }

    .cardname img {
        width: 30px;
        height: 30px;
    }

    .cryptotitle {
        font-size: var(--heading-h5-font-size);
    }

    .cryptocontent {
        flex-direction: column;
        align-items: flex-start;
    }

    .price {
        flex: 1 1 100%;
        margin-top: 5%;
    }

    .deletebtn button {
        padding: 5px 10px;
        font-size: 0.9rem;
    }
}