:root {
    --mainColor: #1A1A1D; 
    --mainColorLight: #4E4E50; 
    --secondaryColor: #6F2232; 
    --textColor: #E5E5E5;
    --optionColor: #EC1D24;
    --activeColor: #7F00FF; 
    --backgroundrgba: rgba(26, 26, 29, 1);
    --heading-h1-font-size: 2.75rem;
    --heading-h2-font-size: 2rem;
    --heading-h3-font-size: 1.5rem;
    --heading-h4-font-size: 1.125rem;
    --heading-h5-font-size: 1rem;
}


.analysis-data-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
}

.analysishead {
    font-size: var(--heading-h2-font-size);
    font-weight: bold;
    color: var(--textColor);
    margin: 20px auto;
    text-align: center;
    width: fit-content;
    border-bottom: 3px solid var(--activeColor);
    padding-bottom: 10px;
    z-index: 1;
    position: relative;
    line-height: 1.5;
}


.Analysisdatacarmain {
    margin: 20px auto;
    padding: 20px;
    width: 100%;
    max-width: 12vw;
    min-width: 12vw;
    box-sizing: border-box;
    z-index: 1;
    position: relative;
}

.currencyimage {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.currencysymbol {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: var(--heading-h3-font-size);
    font-weight: bold;
    color: var(--textColor);
    cursor: pointer;
}

.carousel-container {
    display: flex;
    /* justify-content: center; */
    z-index: 1;
    position: relative;
}


@media (max-width: 768px) {
    .Analysisdatacarmain {
        width: 15%;
        z-index: 1;
        margin: 5% auto;
    }
    
}

@media (max-width: 480px) {
    .Analysisdatacarmain {
        margin: 5% auto;
        padding: 2% 2%;
        width: 45%;
    }
}




