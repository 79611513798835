@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

:root {
--color1: #FB03F5;
--color2: #AA9CFF;
--backgroundcolor:#080808;

}

body {
    margin: 0;
    background: radial-gradient(circle at 20% 20%, var(--color1)  -20%, transparent 40%),
    radial-gradient(circle at 105% 40%, var(--color1) -25%, transparent 20%);
    background-color: var(--backgroundcolor); 
    background-repeat: no-repeat;
}

@media (max-width: 900px) {
    body {
        height: 100%;
        background: radial-gradient(circle at 5% 20%, var(--color1)  -20%, transparent 40%),
        radial-gradient(circle at 105% 90%, var(--color1) -15%, transparent 30%);
        background-color: var(--backgroundcolor);
        background-repeat: no-repeat;
        font-family: "Urbanist", sans-serif;
    }
}
