.chart-card {
    background: transparent;
    border: 1px solid white;
    color: white;
    border-radius: 10px;
    padding: 20px;
    width: 20vw;
    height:20% ;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon img {
    width: 30px;
    height: 30px;
}

.info p {
    margin: 0;
    line-height: 1.2;
}

.percentage {
    color: #00ff00;
}
.chartcontainer{
    margin: 0 5%;
}

.chart-container {
    position: relative;
    height: 70px;
}
@media (max-width:800px){
    .chart-card{
        width:40vw;
    }
}
@media (max-width:500px){
    .chart-card{
        width: 100%;
    }
}